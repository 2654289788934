import {
  EmbeddedScene,
  PanelBuilders, SceneFlexItem, SceneFlexLayout,
  SceneQueryRunner,
} from '@grafana/scenes';


export function getScene() {
  const myTimeSeriesPanel = PanelBuilders.timeseries().setTitle('My first panel');


const data = new SceneQueryRunner({
  datasource: {
    type: 'prometheus',
    uid: 'PEE788C584877020A',
  },
  queries: [
    {
      refId: 'A',
      expr: "sum by(ingestion_type) (iiot_ingestion_metric{asset_name=~\"(2001-CAL-L001|2001-CAL-L002|2001-CAL-L003|2001-CAL-L004|2001-CAL-L005|2001-CAL-L006|2001-COMN-VACM|2001-COMN-WATR|2001-CUT-L001|2001-CoEXTR-L002|2001-CoEXTR-L003|2001-CoEXTR-L004|2001-CoEXTR-L006|2001-EXTR-L001|2001-EXTR-L002|2001-EXTR-L003|2001-EXTR-L004|2001-EXTR-L005|2001-EXTR-L006|2001-EXTRGroup-L001|2001-EXTRGroup-L002|2001-EXTRGroup-L003|2001-EXTRGroup-L004|2001-EXTRGroup-L005|2001-EXTRGroup-L006|2001-MIXG-MIXR-01|2001-MIXG-MIXR-04|2001-PUL-L001|2001-PUL-L002|2001-PUL-L003|2001-PUL-L004|2001-PUL-L005|2001-PUL-L006)\"})",
    },
  ]});

myTimeSeriesPanel.setData(data);

const myPanel = myTimeSeriesPanel.build();

return new EmbeddedScene({
  body: new SceneFlexLayout({
    children: [
      new SceneFlexItem({
        body: myPanel,
      }),
    ],
  }),
});


}
